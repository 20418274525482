import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import LoginPage from "./pages/login/index";
import DashboardPage from "./pages/dashboard/index";
import { jwtDecode } from "jwt-decode";
import ProtectedRoutes from "./components/shared/ProtectedRoutes";

function App() {

  // Check if user is authenticated
  const isAuthenticated = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      return false;
    }
    try {
      let decodedToken = jwtDecode(accessToken);
      if (decodedToken.type !== 'admin') return false;
      let currentDate = new Date();
      // JWT exp is in seconds
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      // If there's an error in decoding, return false
      return false;
    }
  };

  return (
    <Router>
      <Routes>
        <Route element={<ProtectedRoutes isAuthenticated={isAuthenticated} />}>   {/* ProtectedRoutes is a custom component */}
          <Route element={<DashboardPage />} path="/" />
        </Route>
        <Route element={<ProtectedRoutes isAuthenticated={isAuthenticated} isPublic />}>  {/* isPublic is for public routes */}
          <Route path="/login" element={<LoginPage />} />
        </Route>
      </Routes>
    </Router >
  );
}

export default App;
