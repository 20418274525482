import { Outlet, Navigate } from 'react-router-dom';

const ProtectedRoutes = ({ isAuthenticated, isPublic }) => {
    const auth = isAuthenticated();
    return (
        (isPublic && !auth) || (!isPublic && auth) ? (
            // If it's a public route and the user is not authenticated, or if it's a private route and the user is authenticated, allow access
            <Outlet />
        ) : (
            // If the conditions are not met, redirect to the appropriate route
            <Navigate to={isPublic ? '/' : '/login'} />
        )
    );
};

export default ProtectedRoutes;