import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import instance from '../../utils/axios';
import { API } from '../../utils/apiendpoints';
import { useNavigate } from 'react-router-dom';
import life from "./../../assets/icons/lifeGroup.png";

const DashboardPage = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        instance
            .get(API.getAllUsers)
            .then((res) => {
                setLoading(false);
                setUsers(res.data);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        navigate('/login');
    };

    return (
        <Container fluid className="vh-100 login-wrapper">
            <Row className="h-100">
                <Col className="px-4">
                    <div className="w-100">
                        <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
                            <div className="icon-block">
                                <img src={life} alt="life" />
                            </div>
                            <button
                                className="btn btn-danger btn-lg btn-block btn-login mt-3"
                                onClick={handleLogout}
                            >
                                Logout
                            </button>
                        </div>
                        {!loading ? (
                            <Table striped responsive>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Country</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users?.data?.map((user, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{user.email.split('@')[0]}</td>
                                            <td>{user.email}</td>
                                            <td>{user.country}</td>
                                            <td>{user.status}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        ) : (
                            <div className="d-flex justify-content-center align-items-center mt-5">
                                <span
                                    className="spinner-border spinner-border-lg"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default DashboardPage;
