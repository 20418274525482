import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import life from "./../../assets/icons/lifeGroup.png";
import dSocialBack from "./../../assets/icons/dSocialBack.png";
import lifeMistry from "./../../assets/images/lifeMistry.png";
import dSocial from "../../assets/icons/sharedlogo.png";
import Login from "../../components/login/Login";

const LoginPage = () => {

    return (
        <>
            <Container fluid className="vh-100 login-wrapper wrapper">
                <Row className="h-100">
                    <Col xl={4} lg={5} md={6} className="left-col d-none d-md-block tab_50">
                        <div className=" mx-auto text-center left-inner-block d-flex flex-column align-items-center justify-content-end h-100">
                            <h1>
                                A browser

                                <span className="SPAN_BLCK">that is truly</span>
                                family safe.
                            </h1>
                            <div className="mistry-block">
                                <img src={lifeMistry} alt="life_mistry" />
                            </div>
                        </div>
                    </Col>
                    <Col xl={8} lg={7} md={6} className="right-col">
                        <Container className="h-100">
                            <Row className="h-100">
                                <Col className="auth-block">
                                    <div className="form_fixedUp ">
                                        <div className="icon-block">
                                            <img src={life} alt="life" className="tab_desk" />
                                            <img src={dSocial} alt="life-logo" className="res_icon" />
                                        </div>
                                        <Login />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default LoginPage;
