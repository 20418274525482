import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import emailIcon from "./../../assets/icons/emailIcon.png";
import eyeIcon from "./../../assets/icons/eyeIcon.png";
import american from "./../../assets/images/americanMade.png";

import Input from "../shared/Input";
import PasswordInput from "../shared/PasswordInput";

import instance from "../../utils/axios";
import { API } from "../../utils/apiendpoints";




const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange", reValidateMode: "onChange" });
  const [loader, setLoader] = useState(false);

  const onFormSubmit = (data) => {
    setLoader(true);
    instance
      .post(API.loginAdmin, data)
      .then((res) => {
        if (!res?.error) {
          setLoader(false);
          localStorage.setItem("accessToken", res.data?.data?.accessToken);
          navigate("/");
        }
      })
      .catch((error) => {
        setLoader(false);
      });
  };


  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className="form-fields mt-32">
      <div className="w-100 mb-18 spacingErr">
        <Input
          name="email"
          placeholder="Enter Your Email address"
          icon={emailIcon}
          errors={errors}
          register={register("email", {
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: "Please enter a valid Email",
            },
            required: {
              value: true,
              message: "This is required",
            },
          })}
        />
      </div>
      <div className="w-100 mb-18 spacingErr">
        <PasswordInput
          name="password"
          placeholder="Enter Your Password"
          icon={eyeIcon}
          errors={errors}
          register={register("password", {
            required: {
              value: true,
              message: "This is required",
            },
            pattern: {
              value:
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_\-\+=:;"'?\/>.<,{}\[\]])[a-zA-Z\d~`!@#$%^&*()_\-\+=:;"'?\/>.<,{}\[\]]{8,}$/,
              message:
                "Password must be 8 characters and must contain atleast 1 small alphabet, 1 capital alphabet, 1 numeric value and 1 special character",
            },
            minLength: {
              value: 8,
              message: "Password should contain minimum 8 characters long",
            },
            maxLength: {
              value: 20,
              message: "Password should contain maximum 20 characters long",
            },
          })}
        />
      </div>

      <Button
        className="custom-btn mb-32"
        variant="primary"
        type="submit"
      >
        {loader ? (
          <>
            <span
              className="spinner-border me-2 spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </>
        ) : (
          "Login with lifeID"
        )}
      </Button>
      <img className="bottom-img" src={american} alt="american_icon" />
    </form>
  );
};

export default Login;
